import axios, { AxiosRequestConfig } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;

const ApiService = {
  get: function (url: string, config?: AxiosRequestConfig): Promise<any> {
    return axios.get(url, config);
  },
  post: function (url: string, data?, config?: AxiosRequestConfig): Promise<any> {
    return axios.post(url, data, config);
  },
  delete: function (url: string, config?: AxiosRequestConfig): Promise<any> {
    return axios.delete(url, config);
  },
  setPlayerId: function (playerId: string): void {
    axios.defaults.headers.common['Authorization'] = playerId;
  },
  setConnectionId: function (connectionId: string | null): void {
    axios.defaults.headers.common['x-connection-id'] = connectionId;
  },
  toastContainer: null,
  defaultError: function (error: any): void {
    if (!error || !error.response) {
      return;
    }

    if (!ApiService.toastContainer) {
      console.log(error);
      return;
    }

    if (error.response.data && error.response.data.title) {
      ApiService.toastContainer.error(error.response.data.title);
    } else {
      console.log(error.response);
      ApiService.toastContainer.error(`Unknown error ${error.response.status} :-( ${error.response.statusText}`);
    }
  },
};

export default ApiService;
