import React, { useEffect } from 'react';
import { Avatar } from './Avatar';
import './Chat.scss';
import { Player, GameContext } from './models/gameContext';

interface ChatProps {
  hubConnection: signalR.HubConnection;
  messages: any;
  me: Player;
  gameContext: GameContext;
  chatMessage: any;
  setChatMessage: any;
}

export function Chat({ hubConnection, messages, me, gameContext, chatMessage, setChatMessage }: ChatProps): JSX.Element {
  const { game } = gameContext;
  const { gameId } = game;

  if (messages && messages.length > 0) {
    messages = JSON.parse(messages);
  }

  const SendMessage = (): void => {
    if (chatMessage.length > 0) {
      hubConnection.invoke('SendMessage', me.playerId, gameId, chatMessage);
      setChatMessage('');
    }
  };

  const handleChange = (e): void => {
    setChatMessage(e.target.value);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      SendMessage();
    }
  };

  useEffect(() => {
    document.getElementById('chat-text')?.focus();
    const chatBox = document.getElementById('chat-entries');
    chatBox?.scrollTo(0, chatBox.scrollHeight);
  }, [messages]);

  return (
    <div className="game-chat">
      <div id="chat-entries">
        {messages.map((m) => {
          return (
            <div className="chat-entry row" key={`chat-${m.id}`}>
              <div className="avatar col-1">
                <Avatar avatar={m.player.avatar} name={m.player.name} />
              </div>
              <div className="chat-message col-10">{m.message}</div>
            </div>
          );
        })}
      </div>
      <input
        type="text"
        className="chat-text form-control"
        placeholder="Type here..."
        value={chatMessage}
        id="chat-text"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <input type="submit" className="btn btn-info chat-submit" value="Chat" onClick={SendMessage} />
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
