import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from './services/ApiService';
import Moment from 'react-moment';
import { Avatar } from './Avatar';
import './GamesNotCompleted.scss';
import { Player } from './models/gameContext';

interface GamesNotCompletedProps {
  me: Player | undefined;
  hubConnection: signalR.HubConnection;
  initialGames?: GameNotCompleted[];
  showDelete: boolean;
}

interface GameNotCompleted {
  gameId: string;
  createdDate: Date;
  createdBy: string;
  createdByName: string;
  startedDate?: Date;
  players?: Player[];
  round?: number;
  url: string;
}

export function GamesNotCompleted({ me, hubConnection, initialGames, showDelete }: GamesNotCompletedProps): JSX.Element {
  const [games, setGames] = useState(initialGames);
  const [loading, setLoading] = useState(true);

  const Delete = (gameId: string): void => {
    ApiService.delete(`/game/${gameId}`)
      .then(function (response) {
        setGames(response.data);
      })
      .catch(ApiService.defaultError);
  };

  useEffect(() => {
    console.log('SUBscribing from updateGamesNotCompleted');
    hubConnection.on('updateGamesNotCompleted', (data): void => {
      console.log('updateGamesNotCompleted');
      setGames(data);
    });

    ApiService.get('/games/notcompleted')
      .then(function (response) {
        setGames(response.data);
        setLoading(false);
      })
      .catch(ApiService.defaultError);

    return (): void => {
      console.log('UNsubscribing from updateGamesNotCompleted');
      hubConnection.off('updateGamesNotCompleted');
    };
  }, [hubConnection]);

  const Internal = (): JSX.Element => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!games || games.length === 0) {
      return <p>No games running, why not create one? 👇</p>;
    }

    const FormattedDate = ({ game }): JSX.Element | null => {
      const isToday = (someDate: Date): boolean => {
        const today = new Date();
        return (
          someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear()
        );
      };

      const effectiveDate = game.startedDate ? game.startedDate : game.createdDate;
      if (!effectiveDate) {
        return null;
      }

      const format = isToday(new Date(effectiveDate)) ? 'LT' : 'L LT';

      return <Moment format={format}>{effectiveDate}</Moment>;
    };

    const FormattedPlayers = ({ players }: { players: Player[] }): JSX.Element | null => {
      if (!players) {
        return null;
      }

      return (
        <div className="players">
          {players.map((player) => (
            <span className="player" key={`formattedPlayer=${player.playerId}`}>
              {player.name} <Avatar name={player.name} avatar={player.avatar} />
            </span>
          ))}
        </div>
      );
    };

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Host</th>
            <th>Date</th>
            <th>Players</th>
            <th>Round</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game) => {
            let buttonText;
            if (!game.players) {
              buttonText = 'Join';
            } else {
              const existingPlayer = game.players.filter((x) => x.playerId === me.playerId)[0] || null;
              if (existingPlayer) {
                buttonText = 'Rejoin';
              } else {
                buttonText = !game.startedDate && game.players.length < 6 ? 'Join' : 'Spectate';
              }
            }

            return (
              <tr key={`game-${game.gameId}`}>
                <td>{game.createdByName}</td>
                <td>
                  <FormattedDate game={game} />
                </td>
                <td>
                  <FormattedPlayers players={game.players} />
                </td>
                <td>{game.round}</td>
                <td>
                  {showDelete && (
                    <button className="btn btn-danger" onClick={() => Delete(game.gameId)}>
                      Delete
                    </button>
                  )}
                  {!showDelete && (
                    <Link to={game.url}>
                      <button className="btn btn-info">{buttonText}</button>
                    </Link>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="games-not-completed">
      <div className="card">
        <div className="card-header">Running Games</div>
        <div className="card-body">
          <Internal />
        </div>
      </div>
    </div>
  );
}
