import React from 'react';

interface AvatarProps {
  avatar: string;
  name: string;
}

export function Avatar({ avatar, name }: AvatarProps): JSX.Element {
  const filename = `/avatars/avatar-${avatar}.png`;
  return <img src={filename} alt={name} title={name} />;
}
