import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { Game } from './Game';
import { Lobby } from './Lobby';
import { CreatePlayer } from './CreatePlayer';
import 'bootstrap/dist/css/bootstrap.min.css';
import ApiService from './services/ApiService';
import { ToastContainer } from 'react-toastr';
import 'toastr/build/toastr.css';
import 'animate.css/animate.css';
import { Player, GameContext } from './models/gameContext';
import { Deleter } from './Deleter';

interface AppProps {
  initialPlayer?: Player;
  initialGameContext?: GameContext;
  initialHubConnection?: signalR.HubConnection;
}

export function App({ initialPlayer, initialGameContext, initialHubConnection }: AppProps): JSX.Element {
  const playerIdLocalStorage = localStorage.getItem('playerId');
  const [playerId, setPlayerId] = useState(playerIdLocalStorage);
  const [me, setMe] = useState(initialPlayer);
  const [gameContext, setGameContext] = useState(initialGameContext);
  const [hubConnection, setHubConnection] = useState(initialHubConnection);
  const [, setContainer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (playerId) {
      if (!playerId.includes('|')) {
        setPlayerId(null);
      } else {
        const parts = playerId.split('|');
        setMe({ playerId: parts[0], name: parts[1] });
      }
    }

    const hub = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_ENDPOINT + '/gameHub')
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    hub
      .start()
      .then(() => {
        console.log('Connection started: ' + hub.connectionId);
        ApiService.setConnectionId(hub.connectionId);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error while starting connection: ', err);
      });

    setHubConnection(hub);
  }, [playerId]);

  if (!hubConnection || loading) {
    return <div className="container">Loading...</div>;
  }

  if (playerId) {
    ApiService.setPlayerId(playerId);
  } else {
    return (
      <div className="container">
        <CreatePlayer setPlayerId={setPlayerId} setMe={setMe} />
      </div>
    );
  }

  return (
    <div className="container">
      <ToastContainer
        ref={(ref): void => {
          setContainer(ref);
          if (ref) {
            ApiService.toastContainer = ref;
          }
        }}
        className="toast-top-right"
      />
      <Router>
        <Switch>
          <Route exact={true} path="/">
            <Lobby me={me} hubConnection={hubConnection} />
          </Route>
          <Route exact={true} path="/deleter">
            <Deleter me={me} hubConnection={hubConnection} />
          </Route>

          <Route path="/game/:id">
            <Game me={me} gameContext={gameContext} setGameContext={setGameContext} hubConnection={hubConnection} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
