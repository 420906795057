import React from 'react';
import './Card.scss';
import images from './img/cards/*.svg';

export function Card({ card }: { card: string }): JSX.Element {
  const key = card.toLowerCase();
  const image = images[key];
  return (
    <div className="game-card">
      <svg>
        <use xlinkHref={image} />
      </svg>
    </div>
  );
}
