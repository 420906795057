import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from './Card';
import { HandCards } from './HandCards';
import { Avatar } from './Avatar';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './Round.scss';
import { Player, GameContext } from './models/gameContext';

interface RoundProps {
  me: Player;
  gameContext: GameContext;
  isPlayer: boolean;
  processGamePayload: any;
  showTimer: boolean;
}

export function Round({ me, gameContext, isPlayer, processGamePayload, showTimer }: RoundProps): JSX.Element {
  const { game, activeRound, activeHand, players } = gameContext;

  const numPlayers = players.length;
  let seatOrder: string[] = [];
  switch (numPlayers) {
    case 2:
      seatOrder = ['90', '270'];
      break;
    case 3:
      seatOrder = ['90', '210', '330'];
      break;
    case 4:
      seatOrder = ['90', '180', '270', '0'];
      break;
    case 5:
      seatOrder = ['90', '150', '210', '270', '30'];
      break;
    case 6:
      seatOrder = ['90', '150', '210', '270', '300', '30'];
      break;
  }

  if (isPlayer) {
    // Ensure current player is at bottom of screen
    for (let idx = 0; idx < players.length; idx++) {
      const player = players[idx];
      if (me.playerId === player.playerId) {
        break;
      }

      const deg = seatOrder.pop();
      seatOrder.unshift(deg);
    }
  }

  const waitingCardDisplay = <div className="guess-card waiting-to-guess">?</div>;

  let buttonCommand: JSX.Element | null = null;
  if (me) {
    const Timer = (): JSX.Element => {
      return (
        <div className="countdown">
          <CountdownCircleTimer isPlaying durationSeconds={3} size={100} colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]} />
        </div>
      );
    };

    if (game.completedDate) {
      buttonCommand = (
        <Link to="/">
          <button type="button" className="btn btn-primary btn-lg">
            Back to lobby
          </button>
        </Link>
      );
    } else if (showTimer) {
      // Gives players time to see the result before moving on
      buttonCommand = <Timer />;
    }
  }

  return (
    <React.Fragment>
      <div className="circle-container">
        {buttonCommand && <div className="center">{buttonCommand}</div>}

        {players.map((player, idx) => {
          const sequence = idx + 1;
          const degClassName = seatOrder[idx];
          let playerClassName = '';
          const roundPlayer = activeRound.roundPlayerLink.filter((x) => x.playerId === player.playerId)[0];
          let cardDisplay: JSX.Element | null = null;

          if (game.completedDate) {
            const className = player.ranking == 1 ? 'guess-card correct' : 'guess-card';
            cardDisplay = (
              <div className={className}>
                <span>{player.points}</span>
              </div>
            );
            playerClassName = game.wonBy === player.playerId ? 'won-game' : 'lost-game';
          } else if (activeHand) {
            const play = activeHand.plays.filter((x) => x.playerId === player.playerId)[0] || null;

            if (play) {
              cardDisplay = <Card card={play.card} />;
            } else {
              cardDisplay = waitingCardDisplay;

              if (activeHand.awaitingPlayBy === player.playerId) {
                playerClassName = 'waiting';
              }
            }

            if (activeHand.completedDate) {
              playerClassName = activeHand.wonBy === player.playerId ? 'won-hand' : 'lost-hand';
            }
          } else {
            if (roundPlayer.actual !== null && activeRound.completedDate !== null) {
              const className = roundPlayer.actual == roundPlayer.guess ? 'guess-card correct' : 'guess-card wrong';
              cardDisplay = (
                <div className={className}>
                  <span>{roundPlayer.actual}</span>
                </div>
              );
            } else if (roundPlayer.guess !== null) {
              cardDisplay = <div className="guess-card">{roundPlayer.guess}</div>;
            } else {
              cardDisplay = waitingCardDisplay;

              if (activeRound.awaitingGuessBy === player.playerId) {
                playerClassName = 'waiting';
              }
            }
          }

          let actualClassName = 'actual-badge';
          if (roundPlayer.actual == roundPlayer.guess) {
            actualClassName = 'actual-badge correct';
          } else if (roundPlayer.actual > roundPlayer.guess || activeRound.completedDate) {
            actualClassName = 'actual-badge wrong';
          }

          return (
            <div key={`table-player-${sequence}`} className={playerClassName}>
              <div className={`card-display deg-${degClassName}`}>{cardDisplay}</div>
              <div className={`avatar deg-${degClassName}-outer`}>
                <Avatar avatar={player.avatar} name={player.playerName} />
                <div className="player-name">
                  {player.playerName}
                  {roundPlayer && !game.completedDate && (
                    <div className={actualClassName}>
                      {roundPlayer.actual} / {roundPlayer.guess}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {activeRound && isPlayer && <HandCards gameContext={gameContext} processGamePayload={processGamePayload} me={me} />}
    </React.Fragment>
  );
}
