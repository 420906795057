import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface CreatePlayerProps {
  setPlayerId: any;
  setMe: any;
}

export function CreatePlayer({ setPlayerId, setMe }: CreatePlayerProps): JSX.Element {
  const createPlayer = (): void => {
    const name = document.getElementById('name').value;
    if (!name || name.length < 1) {
      return;
    }

    const newId = uuidv4();
    const newPlayerId = `${newId}|${name}`;
    localStorage.setItem('playerId', newPlayerId);
    setPlayerId(newPlayerId);
    setMe({ playerId: newId, name: name });
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      createPlayer();
    }
  };

  useEffect(() => {
    document.getElementById('name').focus();
  }, []);

  return (
    <div className="create-player">
      <h2>Silly Buggers!</h2>
      <table>
        <tbody>
          <tr>
            <td>Name:</td>
            <td>
              <input type="text" id="name" className="form-control" onKeyPress={handleKeyPress} />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <button type="submit" className="btn btn-primary btn-lg" onClick={createPlayer}>
                Create your player
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
