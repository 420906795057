import React from 'react';
import ApiService from './services/ApiService';
import { Card } from './Card';
import './HandCards.scss';
import { GameContext, Player } from './models/gameContext';

interface HandCardsProps {
  processGamePayload: any;
  gameContext: GameContext;
  me: Player;
}

export function HandCards({ processGamePayload, gameContext, me }: HandCardsProps): JSX.Element {
  const { game, activeRound, activeHand } = gameContext;

  const myRound = activeRound.roundPlayerLink.filter((x) => x.playerId === me.playerId)[0] || null;

  const Play = (card: string): void => {
    ApiService.post(`/game/${game.gameId}/play/${card}`)
      .then(function (response) {
        processGamePayload(response.data);
      })
      .catch(ApiService.defaultError);
  };

  if (activeHand && activeHand.awaitingPlayBy == me.playerId) {
    const firstPlay = activeHand.plays[0] || null;
    let mustFollowSuit = false;
    let handSuit: string | null = null;

    const getSuit = (card: string): string => {
      const parts = card.split('_');
      return parts[2];
    };

    if (firstPlay) {
      handSuit = getSuit(firstPlay.card);
      mustFollowSuit = myRound.handRemaining.some((x) => getSuit(x) === handSuit);
    }

    return (
      <div className="my-hand my-turn">
        {myRound.handRemaining.map((card, idx) => {
          let isAllowed = true;
          if (mustFollowSuit) {
            isAllowed = getSuit(card) == handSuit;
          }

          if (isAllowed) {
            return (
              <div className="hand-card is-allowed" key={`myhand-${idx}`} onClick={(): void => Play(card)}>
                <Card card={card} />
              </div>
            );
          }

          return (
            <div className="hand-card not-allowed" key={`myhand-${idx}`}>
              <Card card={card} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="my-hand waiting">
      {myRound.handRemaining.map((card, idx) => (
        <div className="hand-card waiting" key={`myhand-${idx}`}>
          <Card card={card} />
        </div>
      ))}
    </div>
  );
}
