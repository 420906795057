import React from 'react';
import './StatusBar.scss';
import { Avatar } from './Avatar';
import { Player, GameContext } from './models/gameContext';

interface StatusBarProps {
  me: Player;
  gameContext: GameContext;
}

export function StatusBar({ me, gameContext }: StatusBarProps): JSX.Element | null {
  const { game, activeRound, activeHand, players } = gameContext;

  interface OutputMessageProps {
    message: string;
    id?: string | null;
    preventYourTurnMessage?: boolean;
  }

  const OutputMessage = ({ message, id = null, preventYourTurnMessage = false }: OutputMessageProps): JSX.Element => {
    if (!id) {
      return (
        <div className="status-bar">
          <span>{message}</span>
        </div>
      );
    }

    const player = players.filter((x) => x.playerId === id)[0];
    if (!player) {
      return (
        <div className="status-bar">
          <span>{message}</span>
        </div>
      );
    }

    const className = !preventYourTurnMessage && me && me.playerId === player.playerId ? 'status-bar your-turn' : 'status-bar';

    return (
      <div className={className}>
        <span>
          {message} <span className="player-name">{player.playerName}</span>
        </span>
        <Avatar avatar={player.avatar} name={player.playerName} />
      </div>
    );
  };

  if (game.completedDate) {
    return <OutputMessage message={'Game over! Won by: '} id={game.wonBy} preventYourTurnMessage={true} />;
  } else if (activeRound && activeRound.completedDate) {
    return <OutputMessage message={'Round over. How did you do?'} />;
    // TODO: Say who won points, increase the timer
  } else if (activeHand) {
    if (activeHand.completedDate) {
      return <OutputMessage message={'Hand won by '} id={activeHand.wonBy} preventYourTurnMessage={true} />;
    } else {
      return <OutputMessage message={'Waiting for player: '} id={activeHand.awaitingPlayBy} />;
    }
  } else if (activeRound) {
    if (activeRound.awaitingGuessBy) {
      return <OutputMessage message={'Waiting for guess by '} id={activeRound.awaitingGuessBy} />;
    }

    return <OutputMessage message={'All bets are in! Starting round...'} />;
  } else if (!game.startedDate) {
    return <OutputMessage message={'Waiting for host to start: '} id={game.createdBy} />;
  }

  return null;
}
