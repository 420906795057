import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from './services/ApiService';
import { GamesNotCompleted } from './GamesNotCompleted';
import { Player } from './models/gameContext';

interface LobbyProps {
  me: Player | undefined;
  hubConnection: signalR.HubConnection;
}

export function Lobby({ me, hubConnection }: LobbyProps): JSX.Element {
  const [gameId, setGameId] = useState(null);

  if (gameId) {
    const redirectTo = `/game/${gameId}`;
    return <Redirect to={redirectTo} />;
  }

  const CreateGame = (): void => {
    ApiService.post('/game/create')
      .then(function (response) {
        setGameId(response.data[0].game.gameId);
      })
      .catch(ApiService.defaultError);
  };

  return (
    <>
      <h1>Silly Buggers!</h1>

      <GamesNotCompleted me={me} hubConnection={hubConnection} />

      <button type="button" className="btn btn-primary" onClick={CreateGame}>
        Create a new game
      </button>
    </>
  );
}
