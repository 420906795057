import React from 'react';
import ApiService from './services/ApiService';
import './CardGuesser.scss';
import { GameContext } from './models/gameContext';

interface CardGuesserProps {
  gameContext: GameContext;
  processGamePayload: any;
  maxCard: number;
}

export function CardGuesser(prop: CardGuesserProps): JSX.Element {
  const { gameContext, processGamePayload, maxCard } = prop;
  const Guess = (guess: number): void => {
    ApiService.post(`/game/${gameContext.game.gameId}/guess/${guess}`)
      .then(function (response) {
        processGamePayload(response.data);
      })
      .catch(ApiService.defaultError);
  };

  const guessOptions: JSX.Element[] = [];
  for (let index = 0; index <= maxCard; index++) {
    guessOptions.push(
      <a className="guess-option" key={'guess-' + index} onClick={(): void => Guess(index)}>
        {index}
      </a>
    );
  }

  return (
    <div className="guess-options">
      <h5>Guess your wins</h5>
      {guessOptions}
    </div>
  );
}
