import React from 'react';
import { Avatar } from './Avatar';
import './RoundsTable.scss';
import { GameContext } from './models/gameContext';

export function RoundsTable({ gameContext }: { gameContext: GameContext }): JSX.Element {
  const { activeRound, players, rounds } = gameContext;

  return (
    <table className="rounds-table table table-striped table-sm">
      <thead>
        <tr>
          <th>&nbsp;</th>
          {players.map((player) => (
            <th key={`leaderboard-header-` + player.playerId}>
              <Avatar avatar={player.avatar} name={player.playerName} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rounds.map((round) => {
          const rowClassName = activeRound.roundId === round.roundId ? 'leaderboard-row active-round' : 'leaderboard-row';

          return (
            <tr className={rowClassName} key={`leaderboard-row-` + round.roundId}>
              <td>{round.cards}</td>
              {players.map((player) => {
                const playerRound = round.roundPlayerLink.filter((x) => x.playerId === player.playerId)[0];

                let className = '';
                if (playerRound.actual > playerRound.guess) {
                  className = 'wrong';
                } else if (round.roundId != activeRound.roundId || activeRound.completedDate !== null) {
                  className = playerRound.actual === playerRound.guess ? 'correct' : 'wrong';
                }

                return (
                  <td className={className} key={`leaderboard-row-` + round.cards + player.playerId}>
                    <span>{playerRound.guess}</span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {(rounds.length > 1 || activeRound.completedDate !== null) && (
        <tfoot>
          <tr className="total-row">
            <td>Total</td>
            {players.map((player) => {
              return <td key={`leaderboard-points-` + player.playerId}>{player.points}</td>;
            })}
          </tr>
          <tr className="ranking-row">
            <td>Rank</td>
            {players.map((player) => {
              return <td key={`leaderboard-rank-` + player.playerId}>{player.ranking}</td>;
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
}
