import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import ApiService from './services/ApiService';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Round } from './Round';
import { Card } from './Card';
import { RoundsTable } from './RoundsTable';
import { CardGuesser } from './CardGuesser';
import { Avatar } from './Avatar';
import { WaitingToStart } from './WaitingToStart';
import { StatusBar } from './StatusBar';
import { Chat } from './Chat';
import './Game.scss';
import { Player, GameContext, GamePlayerLink } from './models/gameContext';

interface GameProps {
  me: Player | undefined;
  gameContext: GameContext | undefined;
  setGameContext: React.Dispatch<React.SetStateAction<GameContext | undefined>>;
  hubConnection: signalR.HubConnection;
}

export function Game({ me, gameContext, setGameContext, hubConnection }: GameProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [messagesJson, setMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState('');
  const [showTimer, setShowTimer] = useState(false);
  const params = useParams();
  const gameId = params.id;

  let messages = [];
  if (messages && messages.length > 0) {
    messages = JSON.parse(messages);
  }

  let isPlayer = false;

  const saveGameContext = (data: GameContext): void => {
    const gameId = data.game.gameId;
    const sessionKey = `game-${gameId}`;
    sessionStorage.setItem(sessionKey, JSON.stringify(data));
    setGameContext(data);
  };

  const processGamePayload = (data: GameContext[]): void => {
    saveGameContext(data[0]);

    if (data.length === 2 && data[1]) {
      setShowTimer(true);
      setTimeout(() => {
        setShowTimer(false);
        saveGameContext(data[1]);
      }, 3000);
    }
  };

  const Refresh = (gameId: string): void => {
    ApiService.get('/game/' + gameId)
      .then((response) => {
        if (!response.data) {
          console.log('Issue with the data?');
          return;
        }

        processGamePayload(response.data);
        setLoading(false);
      })
      .catch(console.log);
  };

  const AddMessage = (messageData): void => {
    messages.push(messageData);
    setMessages(JSON.stringify(messages));
  };

  useEffect(() => {
    hubConnection.on('update', (data) => {
      console.log(data);

      // TODO: I know, I'm pretty sure this absolutely isn't the way to do it
      const gameContainer = document.getElementById('game-container');
      if (!gameContainer) {
        return;
      }

      const currentGameId = gameContainer.getAttribute('data-gameid');
      if (!currentGameId) {
        return;
      }

      if (!data) {
        return;
      }

      if (!data.length || data.length === 0) {
        return;
      }

      if (!data[0].game || data[0].game.gameId !== currentGameId) {
        console.log('For a different game, ignoring');
        return;
      }

      processGamePayload(data);
    });

    hubConnection.on('receiveMessage', (messageData) => {
      // TODO: I know, I'm pretty sure this absolutely isn't the way to do it
      const gameContainer = document.getElementById('game-container');
      if (!gameContainer) {
        return;
      }

      const currentGameId = gameContainer.getAttribute('data-gameid');
      if (!currentGameId) {
        return;
      }

      if (!messageData || !messageData.gameId || messageData.gameId !== currentGameId) {
        console.log('For a different game, ignoring'); // TODO: Use SignalR to only send to the right clients
        return;
      }

      AddMessage(messageData);
    });

    Refresh(gameId);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const { game, players, activeRound } = gameContext;

  const Internal = (): JSX.Element => {
    isPlayer = players.some((x) => x.playerId === me.playerId);

    if (game.startedDate === null) {
      return <WaitingToStart me={me} isPlayer={isPlayer} gameContext={gameContext} processGamePayload={processGamePayload} />;
    }

    let winningPlayer: GamePlayerLink | null = null;
    if (game.completedDate) {
      winningPlayer = players.filter((x) => x.playerId == game.wonBy)[0] || null;
    }

    return (
      <div className="row">
        <div className="col-md-8">
          {activeRound && (
            <Round me={me} isPlayer={isPlayer} gameContext={gameContext} processGamePayload={processGamePayload} showTimer={showTimer} />
          )}
        </div>
        <div className="col-md-4">
          <StatusBar me={me} gameContext={gameContext} />

          {isPlayer && (
            <Chat
              hubConnection={hubConnection}
              me={me}
              messages={messagesJson}
              gameContext={gameContext}
              chatMessage={chatMessage}
              setChatMessage={setChatMessage}
            />
          )}

          {!game.completedDate && activeRound && (
            <div className="trump-card" title="Trump Card">
              <Card card={activeRound.trumpCard} />
            </div>
          )}
          {game.completedDate && winningPlayer && (
            <div className="game-winner">
              <h5>Winner!</h5>
              <Avatar avatar={winningPlayer.avatar} name={winningPlayer.playerName} />
            </div>
          )}

          {isPlayer && activeRound && activeRound.awaitingGuessBy !== null && activeRound.awaitingGuessBy === me.playerId && (
            <CardGuesser gameContext={gameContext} processGamePayload={processGamePayload} maxCard={activeRound.cards} />
          )}

          <RoundsTable gameContext={gameContext} />
        </div>
      </div>
    );
  };

  return (
    <div id="game-container" data-gameid={game.gameId}>
      <Internal />
      <Link to={'/'}>
        <button className="back-to-lobby btn btn-sm btn-secondary">&lt;&lt; Back to Lobby</button>
      </Link>
    </div>
  );
}
