import React from 'react';
import { GamesNotCompleted } from './GamesNotCompleted';
import { Player } from './models/gameContext';

interface DeleterProps {
  me: Player | undefined;
  hubConnection: signalR.HubConnection;
}

export function Deleter({ me, hubConnection }: DeleterProps): JSX.Element {
  return (
    <>
      <h1>Deleter</h1>

      <GamesNotCompleted me={me} hubConnection={hubConnection} showDelete={true} />
    </>
  );
}
