import React from 'react';
import ApiService from './services/ApiService';
import { Avatar } from './Avatar';
import { StatusBar } from './StatusBar';
import './WaitingToStart.scss';
import { GameContext, Player } from './models/gameContext';

interface WaitingToStartProps {
  gameContext: GameContext;
  processGamePayload: any;
  isPlayer: boolean;
  me: Player;
}

export function WaitingToStart({ gameContext, processGamePayload, isPlayer, me }: WaitingToStartProps): JSX.Element {
  const { game, players } = gameContext;

  const JoinGame = (avatar: string): void => {
    ApiService.post(`/game/${game.gameId}/join/${avatar}`)
      .then(function (response) {
        processGamePayload(response.data);
      })
      .catch(ApiService.defaultError);
  };

  const StartGame = (): void => {
    ApiService.post(`/game/${game.gameId}/start`)
      .then(function (response) {
        processGamePayload(response.data);
      })
      .catch(ApiService.defaultError);
  };

  const AvailableAvatars = (): JSX.Element => {
    const taken = players.map((x) => x.avatar);
    const available = ['A', 'B', 'C', 'D', 'E', 'F'].filter((x) => !taken.includes(x));

    return (
      <div className="available-avatars">
        <h2>Choose your avatar!</h2>
        <div className="row">
          {available.map((avatar) => (
            <div className="col-2" key={`avatar-${avatar}`} onClick={(): void => JoinGame(avatar)}>
              <div className="avatar">
                <Avatar avatar={avatar} name={''} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="waiting-to-start">
      <h2>Waiting to start</h2>

      {players.length == 0 && <div className="no-players">No players yet</div>}

      {players.length > 0 && (
        <div className="players row">
          {players.map((player) => (
            <div className="player col-2" key={`player-${player.playerId}`}>
              <div className="player-name">{player.playerName}</div>
              <div className="avatar">
                <Avatar avatar={player.avatar} name={player.playerName} />
              </div>
            </div>
          ))}
        </div>
      )}

      {!isPlayer && players.length < 6 && <AvailableAvatars />}
      {isPlayer && players.length >= 2 && (
        <div className="ready-to-start">
          {me.playerId === game.createdBy && (
            <button type="button" className="btn btn-primary" onClick={StartGame}>
              Start Game!
            </button>
          )}
          <StatusBar me={me} gameContext={gameContext} />
        </div>
      )}
    </div>
  );
}
